import React, {useEffect, useState} from "react";
import L from "leaflet";
import Store from "../store/store";
import {Search} from "./search";

export function App() {
  const [store, setStore] = useState<Store>()

  useEffect(() => {
    if (store) {
      // map is already initialized
      return
    }
    const map = L.map("map")
    //L.tileLayer(`${process.env.TILES_SERVER}/{z}/{x}/{y}.png`, {
    L.tileLayer(`https://tile.openstreetmap.org/{z}/{x}/{y}.png`, {
      maxZoom: 19,
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      id: 'base'
    }).addTo(map);

    map.setView([53.57809, 9.72904], 18)

    const newStore = new Store(map)
    setStore(newStore)
  }, [store])

  if (!store) {
    return null
  }

  return (
    <>
      <div className="search-container">
        <Search store={store} />
      </div>
    </>
  );
}

