import {NonFunctionProperties} from "../types";
import {LatLngTuple} from "leaflet";

export default class Building {
  constructor(building: NonFunctionProperties<Building>) {
    Object.assign(this, building)
  }

  ID: string = ""
  Name: string = ""
  Koordinaten: LatLngTuple[] = []
  Treppen: LatLngTuple[] = []

}
