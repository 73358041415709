import React, {useCallback, useMemo, useState} from "react"
import Select, {ActionMeta, GroupBase, OnChangeValue, Options, OptionsOrGroups, PropsValue} from "react-select"
import L from "leaflet";
// @ts-ignore
import staircaseIconUrl from "../images/staircase.svg"

import Store from "../store/store";
import Room from "../store/room";
import {FilterOptionOption} from "react-select/dist/declarations/src/filters";

interface Props {
  store: Store
}

interface RoomSelection {
  room: Room;
  roomPosition: L.Circle,
  buildingOutline?: L.Polygon,
  buildingStaircases: L.Marker[],
}

const staircaseIcon = L.icon({
  iconUrl: staircaseIconUrl,
  iconSize: [32, 32],
})

export function Search({ store }: Props) {

  const [selectedRoom, setSelectedRoom] = useState<RoomSelection | null>(null)
  const [selectValue, setSelectValue] = useState<PropsValue<Room>>(null)

  const onChange = (option: OnChangeValue<Room, false>, m: ActionMeta<Room>) => {
    setSelectValue(option)

    if (!option) {
      return
    }

    if (selectedRoom) {
      selectedRoom.roomPosition.closePopup()
      selectedRoom.roomPosition.removeFrom(store.map)
      selectedRoom.buildingOutline?.removeFrom(store.map)

      for (const staircase of selectedRoom.buildingStaircases) {
        staircase.removeFrom(store.map)
      }

      setSelectedRoom(null)
    }

    const room = store.roomMap[option.NID]
    focusRoom(room)
  }

  const focusRoom = (room: Room | null) => {
    if (!room) {
      console.warn("Room not found")
      return
    }

    const coords = room.Geo_Koordinaten
    if (!coords) {
      console.warn(`Room ${room.ID} has no geo coordinates`)
      return
    }

    const roomPosition = L.circle(coords, {
      color: "red",
      fillColor: "#f03",
      fillOpacity: .5,
      radius: 2
    })

    roomPosition.bindPopup(room.popupHTML())

    const newSelectedRoom: RoomSelection = {
      room,
      roomPosition,
      buildingStaircases: [],
    }

    const building = store.buildingForRoom(room)

    if (building) {
      newSelectedRoom.buildingOutline = L.polygon(building.Koordinaten, {
        color: "grey",
        fillColor: "#777",
        fillOpacity: .5,
      })
      newSelectedRoom.buildingOutline.addTo(store.map)

      for (const staircase of building.Treppen) {
        const m = L.marker(staircase, {
          icon: staircaseIcon,
        })
        m.addTo(store.map)
        newSelectedRoom.buildingStaircases.push(m)
      }
    }

    roomPosition.addTo(store.map)
    roomPosition.openPopup()
    store.map.flyTo(coords, 19)

    setSelectedRoom(newSelectedRoom)
    setSelectValue(newSelectedRoom.room)
    
    window.history.replaceState(null, "", `?${encodeURIComponent(room.ID)}`)
  }

  // On initial load, read selected room from url and set it as the initially selected room
  useMemo(() => {
    // @ts-ignore
    const roomLabel = window.location.href.split('?')[1]
    let room = store.rooms.find(r => [r.AID, r.Bezeichnung, r.ID, r.Mitarbeiterkürzel].includes(roomLabel))

    if (room == null) {
      return
    }

    // clean url
    //window.history.replaceState(null, "", "/")

    if (!room) {
      return null
    }

    focusRoom(room)
  }, [])

  const filterOption = useCallback((option: FilterOptionOption<Room>, inputValue: string) => {
    const lower = inputValue.toLowerCase()
    return option.data.ID.toLowerCase().includes(lower) ||
      option.data.Bezeichnung?.toLowerCase().includes(lower) ||
      option.data.Mitarbeiterkürzel?.toLowerCase().includes(lower) ||
      option.data.Name?.toLowerCase().includes(lower) ||
      false
  }, [])
  
  const options = store.groupedSelectOptions;
  const bureau = options.find(group => group.label === 'Büroraum');
  bureau!.options = bureau!.options.filter(room => !['276', '277'].includes(room.NID));

  return (
    <Select<Room>
      options={options}
      className="search"
      onChange={onChange}
      value={selectValue}
      placeholder={"Suche nach Raum oder Mitarbeiter"}
      filterOption={filterOption}
    />
  )
}
