import L from "leaflet"
import groupBy from "lodash/groupBy";
import { parse } from "csv-parse/dist/esm/sync"

import Room from "./room";
import Building from "./building";

import roomsCsv from "bundle-text:../../krg/raumbezeichnungen.csv"
import buildingsCsv from "bundle-text:../../krg/gebaeude.csv"

export default class Store {

  constructor(public map: L.Map) {
    // set rooms
    this.rooms = parse(roomsCsv, {
      columns: true
    }).map((room: any) => {
      if (room.Geo_Koordinaten) {
        const split = room.Geo_Koordinaten.split(",")
        room.Geo_Koordinaten = [
          Number.parseFloat(split[0]),
          Number.parseFloat(split[1]),
        ];
      } else {
        room.Geo_Koordinaten = null
      }

      return new Room(room);
    }).map((room: Room) => {
      room.Typ = room.Typ?.trim()
      room.AID = room.AID?.trim()
      room.Bezeichnung = room.Bezeichnung?.trim()
      room.InDesignName = room.InDesignName?.trim()
      room.Name = room.Name?.trim()
      return room
    })

    // set buildings
    this.buildings = parse(buildingsCsv, {
      columns: true,
    }).map((building: any) => {
      building.Koordinaten = JSON.parse(building.Koordinaten || "[]")
      building.Treppen = JSON.parse(building.Treppen || "[]")
      return new Building(building)
    })

    // set grouped select options and room map
    const groupedRooms = groupBy(this.rooms.filter(r => r.Geo_Koordinaten), "Typ")
    for (const allowedType of ["Hörsaal", "Büroraum", "Laborraum", "PC Raum", "Seminarraum", "CoWorking"]) {
      const options = groupedRooms[allowedType];

      if (options && options.length) {
        const group = {
          label: allowedType,
          options: options,
        }

        this.groupedSelectOptions.push(group)

        for (const room of options) {
          this.roomMap[room.NID] = room
        }
      }
    }
  }

  buildingForRoom(room: Room): Building | null {
    const key = room.buildingKey()
    return this.buildings.find(b => b.ID === key) || null
  }

  rooms: Room[] = []
  buildings: Building[] = []
  groupedSelectOptions: { label: string, options: Room[] }[] = []
  roomMap: {[nid: string]: Room} = {}

}
