import React from "react";
import ReactDOM from "react-dom"
import { App } from "./components/app"

const init = () => {
  const app = document.getElementById("app")
  ReactDOM.render(<App />, app)
}

init()
