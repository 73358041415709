import {LatLngTuple} from "leaflet"
import md5 from "md5"
import {NonFunctionProperties} from "../types";

export default class Room {

  constructor(room: NonFunctionProperties<Room>) {
    Object.assign(this, room)
  }

  /** e.g. "32" */
  NID: string = "";
  /** e.g. "A0.10" */
  ID: string = "";
  Geo_Koordinaten: LatLngTuple | null = null
  /** e.g. "Seminarraum" */
  Typ: string = ""
  /** e.g. "SR03" */
  AID: string = ""
  /** e.g. "Agnesi" */
  Bezeichnung?: string
  InDesignName?: string
  Name?: string
  URL: string = ""
  Größe_m2: string = ""
  alte_Bezeichnung: string | null = null
  Mitarbeiterkürzel?: string;
  
  description?: string;
  link?: string;
  imageUrl?: string;

  // used by react-select
  get label(): string {
    const parts = [
      this.InDesignName,
    ]

    if (this.Name) {
      parts.push(`— ${this.Name}`)
    }

    return parts.join(" ")
  }

  // used by react-select
  get value(): string {
    return this.NID
  }

  /**
   * @return "A", "B", "C", "D, "E", "F" or "G"
   */
  buildingKey(): string {
    return this.ID[0]
  }

  /**
   * @returns "0", "1", "2" or "U"
   */
  level(): string {
    return this.ID[1]
  }

  levelFormatted(): string {
    const l = this.level()

    if (l == "U") {
      return "Untergeschoss"
    } else if (l == "0") {
      return "Ebene 0"
    } else {
      return `${l}. Obergeschoss`
    }
  }

  popupHTML(): string {
    return `<div class="popup-container">
      <h3 style="margin: 0;">${this.ID} — ${this.levelFormatted()}</h3>
      <h3 style="margin: 0; margin-bottom: 1em;">${this.label} ${this.Mitarbeiterkürzel && `| ${this.Mitarbeiterkürzel}`}</h3>
      <div class="popup-row">
        <div class="popup-text">
          <p style="margin: 0;">
            ${(this.description != null) ? this.description : ''}
          </p>
          ${(this.link != null) ? `<a href="${this.link}">zur Website</a>` : ''}
        </div>
        <div>${(this.imageUrl != null) ? `<img src="${this.imageUrl}">` : ''}</div>
      </div>
    </div>`;
  }

  hashedRoomId(): string {
    const url = new URL(this.URL)
    return url.searchParams.get("rid")!!
  }

}
